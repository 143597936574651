<template>
    <main id="liste" v-cloak>
        <!-- A changer par le nom du tiers sur lequel on effectue les opérations de compta -->
        <HeaderTab :title="$t('gynecologie.bilan_reproduction')" :swiper_tabs="swiper_tabs" />

        <div id="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div>
                            <router-view></router-view>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>


<script type="text/javascript">

    import Navigation from "@/mixins/Navigation.js";

	export default {
		name: "Bilans",
		mixins: [Navigation],
		data () {
			return {
                param_tab: '',
                actual_tab: 'invoiceList',

                swiper_tabs: [{
                        id: 'etat_jumenterie',
						label: 'gynecologie.etat_jumenterie',
						active: false,
                        href : 'etatJumenterie'
					},
                    {
                        id: 'croisements',
                        label: 'gynecologie.croisements',
                        active: false,
                        href : 'croisements'
                    },
                    {
                        id: 'stat_repro',
                        label: 'gynecologie.stat_repro',
                        active: false,
                        href : 'statistiquesRepro'
                    },
                    {
                        id: 'fertilite',
                        label: 'gynecologie.fertilite',
                        active: false,
                        href: 'fertiliteRepro'
                    }
				]
			}
		},

		mounted() {
            this.param_tab = this.$route.params.actual_tab
            this.init_component()
		},

		methods: {
            async init_component() {
                // CHECK ICI SI this.$route.params.tiers_id
                // est valide et tout et tout
            }
		},
		components: {
            HeaderTab: () => import('@/components/HeaderTab'),
		}
	}
</script>
